import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"

const Wrapper = styled(Row)`
  max-width: 750px;
  padding: 30px 0 0 0;
`
const BlogCol = styled(Col)`
  padding: 0;
`
const Post = styled.div`
  margin-bottom: 100px;
`
export const HeroImage = styled(Img)`
  @media only screen and (max-width: 520px) {
    height: 300px;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`
const ExcerptWrapper = styled.div`
  max-width: 580px;
`
const HeadingOne = styled.h1`
  ${props => props.theme.main.fonts.headingOne};
  font-size: 36px;
  line-height: 1.32;
  text-align: center;
  padding-bottom: 60px;

  @media only screen and (max-width: 767px) {
    font-size: 30px;
  }
`
const HeadingTwo = styled.h2`
  ${props => props.theme.main.fonts.headingTwo};
  color: #000000;
  font-family: "Libre Franklin";
  font-weight: bold;
  padding-top: 20px;
  text-decoration: none;

  @media only screen and (max-width: 767px) {
    font-size: 26px;
  }
`
const HeadingThree = styled.h4`
  ${props => props.theme.main.fonts.headingThree};

  font-size: 16px;
  font-weight: 600;
  margin: 7px 0 0 0;
  @media only screen and (max-width: 824px) {
    font-size: 14px;
  }
`
const HeadingFour = styled.h5`
  ${props => props.theme.main.fonts.headingFour};
  color: #7f7a78;
  font-size: 13px;
  line-height: 1.8;
`
const ParagraphTwo = styled.p`
  ${props => props.theme.main.fonts.paragraphTwo};
  font-size: 16px;
`
export const ProfileWrapper = styled.div`
  width: 100%;
  height: 52px;
`
export const ProfileImageWrapper = styled.div`
  width: 52px;
  height: 52px;
  float: left;
  margin-right: 10px;
`
export const ProfileImage = styled(Img)`
  border-radius: 100px;
`
export const ProfileTextWrapper = styled.div`
  max-width: 650px;
  height: 52px;
  float: left;
`

export default ({ data }) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Wrapper>
            <BlogCol>
              <HeadingOne>Savor Blog</HeadingOne>
              {data.allContentfulBlogPost.edges.map(({ node }) => (
                <Post>
                  <StyledLink to={node.slug}>
                    <HeroImage fluid={node.heroImage.fluid} />
                    <HeadingTwo>{node.title}</HeadingTwo>
                    <ExcerptWrapper>
                      <ParagraphTwo>
                        {documentToPlainTextString(node.body.json).substring(
                          0,
                          155
                        )}
                        ...
                      </ParagraphTwo>
                    </ExcerptWrapper>
                    {node.author ? (
                      <ProfileWrapper>
                        <ProfileImageWrapper>
                          <ProfileImage fixed={node.author.photo.fixed} />{" "}
                        </ProfileImageWrapper>
                        <ProfileTextWrapper>
                          <HeadingThree>{node.author.name}</HeadingThree>
                          <HeadingFour>{node.publishDate}</HeadingFour>
                        </ProfileTextWrapper>
                      </ProfileWrapper>
                    ) : (
                      ""
                    )}
                  </StyledLink>
                </Post>
              ))}
            </BlogCol>
          </Wrapper>
        </Col>
      </Row>
    </Container>
  )
}

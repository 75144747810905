import React from 'react';
import Layout from 'components/Layout/Layout';
import SEO from 'components/Layout/Seo';
import Blog from 'components/Blog/Blog';

const BlogPage = ({data}) => (
  <Layout>
    <SEO title="Blog" />
    <Blog data={data} />
  </Layout>
)

export default BlogPage

export const query = graphql`
  query {
    allContentfulBlogPost(sort: { fields: publishDate, order: DESC }) {
      edges {
        node {
          slug
          title
          author {
            name
            photo {
              fixed(width: 52, height: 52) {
                ...GatsbyContentfulFixed
              }
            }
          }
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            fluid(quality: 90, maxWidth: 4160, maxHeight: 2300) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          body {
            json
          }
        }
      }
    }
  }
`